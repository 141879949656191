import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import { ECAMPUS_BASE_URL, ECAMPUS_API_URL } from './constants';
import Publication from './Publication';
import publicationTypes from './types.json';
import cx from 'classnames';

const ECampusLink = ({ hisResId, suffix = '' }) => (
  <>
    <a
      href={`${ECAMPUS_BASE_URL}/qisserver/a/cs.psv.frontend/person/view/${hisResId}${suffix}`}
      target="_blank"
      rel="noreferrer"
      className="ecampus"
    >
      <FormattedMessage id="View on eCampus" defaultMessage="View on eCampus" />{' '}
      <VoltoIcon name={arrowSVG} />
    </a>
    <br style={{ clear: 'both' }} />
  </>
);

const View = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pubs, setPubs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [orgUnitDefaultText, setOrgUnitDefaultText] = useState('');

  const limit = props.data?.limit || 50;
  const pubsIds = props.data?.pubsids || [];
  const citationStyle = props.data?.citation_style || false;

  const items =
    props.data?.items?.length > 0
      ? props.data.items
          .filter((i) => i.href?.length > 0 && i.href[0].ecampus_id)
          .map((i) => i.href[0])
      : [];
  let personIds = items
    .filter((i) => i.portal_type === 'Person')
    .map((i) => i.ecampus_id);
  const orgUnitIds = items
    .filter((i) => i.portal_type === 'OrgUnit')
    .map((i) => i.ecampus_id);

  const baseCriteria = [];
  const filterByType =
    props.data?.filter_by_type && props.data.filter_by_type.length > 0;
  if (filterByType) {
    baseCriteria.push({
      field: 'Publication.publicationType.id',
      value: props.data.filter_by_type.join(','),
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let results = [];
      let openRequests = 0;
      let totalTotalCount = 0;
      let curOrgUnitDefaultText = '';

      setIsLoading(true);

      /* to enforce re-render even when no pubs can be loaded */
      if (personIds.length + orgUnitIds.length + pubsIds.length === 0)
        setTimeout(() => myCallback());

      const addResults = (data, columns, totalCount) => {
        /* normalize means converting simple array into
           dictionary where the keys are field names
        */
        const normalizeItem = (item, columns) => {
          let res = {};
          columns.forEach((col, index) => {
            res[col.id] = item[index];
          });
          return res;
        };
        results = results.concat(
          data
            .map((item) => normalizeItem(item, columns))
            /* use filter to eliminate duplicates */
            .filter(
              (i) =>
                results.findIndex(
                  (r) => r['Publication.id'] === i['Publication.id'],
                ) === -1,
            ),
        );
        totalTotalCount += totalCount;
      };

      const myCallback = () => {
        openRequests -= 1;
        if (openRequests <= 0) {
          /* all requests terminated:
            1. sort by year
            2. apply limit (in some cases unneccessary)
            3. update state
          */
          results.sort((a, b) =>
            a['Publication.releaseYear'] > b['Publication.releaseYear']
              ? -1
              : a['Publication.releaseYear'] < b['Publication.releaseYear']
              ? 1
              : 0,
          );

          setPubs(results.slice(0, limit));
          setTotalCount(totalTotalCount);
          if (curOrgUnitDefaultText.length > 0)
            setOrgUnitDefaultText(curOrgUnitDefaultText);
          setIsLoading(false);
        }
      };

      /* fetch pubs of specified person */
      personIds.forEach((hisResId) => {
        openRequests += 1;
        const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;
        const postData = {
          search: {
            configFile: 'fs/res/searchPublicationForPersonProfile.xml',
            groups: [
              {
                criteria: baseCriteria.concat([
                  {
                    field: 'Publication.creators.person.id',
                    value: hisResId,
                  },
                ]),
              },
            ],
          },
          offset: 0,
          /* base criterium for publicationType is ignored with config
             searchPublicationForPersonProfile.xml, so we can only apply
             limit after manually filtering for types.
          */
          limit: props.data?.filter_by_type ? -1 : limit,
          sortCriteria: [
            { ascending: false, field: 'Publication.releaseYear' },
          ],
        };
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let dataData = data.data;

            if (data?.data) {
              if (filterByType) {
                /* map type IDs to names ... */
                let publicationTypesDict = {};
                publicationTypes.forEach((t) => {
                  publicationTypesDict[t.id] = t.label;
                });
                const filters = props.data.filter_by_type
                  .filter(
                    (tId) =>
                      Object.keys(publicationTypesDict).indexOf(tId) > -1,
                  )
                  .map((tId) => publicationTypesDict[tId]);
                /* ... and use these to filter publications */
                dataData = dataData.filter((r) => filters.indexOf(r[0]) > -1);
              }
              addResults(dataData, data.columns, data.totalCount);
            }
            myCallback();
          });
      });

      /* fetch pubs of org unit */
      orgUnitIds.forEach(async (orgUnitId) => {
        const ourl = `${ECAMPUS_API_URL}/cs/psv/orgunitobj/${orgUnitId}/subtree`;
        const defaulttext = await fetch(ourl)
          .then((response) => response.json())
          .then((data) => data.data.defaulttext);
        curOrgUnitDefaultText = defaulttext;

        if (defaulttext?.length > 0) {
          openRequests += 1;
          const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;
          const postData = {
            search: {
              configFile: 'fs/res/searchPublication.xml',
              groups: [
                {
                  criteria: baseCriteria.concat([
                    {
                      field:
                        'Publication.creators.creatorOrgunits.orgunit.defaulttext',
                      value: defaulttext,
                    },
                  ]),
                  criteriaGroups: [],
                  groupLabel: 'fs.res.common.basicData',
                },
              ],
            },
            offset: 0,
            limit: limit,
            sortCriteria: [
              { ascending: false, field: 'Publication.releaseYear' },
            ],
          };
          fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data?.data)
                addResults(
                  /* manually check if OrgUnits defaulttext (in col 5)
                     is actually equal to demanded `defaulttext`, since
                     loadSearchResult endpoint only checks for simliarty
                     (can result in false positives)
                  */
                  data.data.filter((i) => i[5] === defaulttext),
                  data.columns,
                  data.totalCount,
                );
              myCallback();
            });
        }
      });

      /* fetch additional pubs */
      pubsIds.forEach((pubId) => {
        openRequests += 1;
        const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;
        const postData = {
          search: {
            configFile: 'fs/res/searchPublication.xml',
            groups: [
              {
                criteria: baseCriteria.concat([
                  {
                    field: 'Publication.id',
                    value: pubId,
                  },
                ]),
              },
            ],
          },
          offset: 0,
          limit: 1,
          sortCriteria: [
            { ascending: false, field: 'Publication.releaseYear' },
          ],
        };
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data?.data)
              addResults(data.data, data.columns, data.totalCount);
            myCallback();
          });
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.items, props.data.pubsids, props.data.filter_by_type, limit]);

  let curYear = -1;

  /* total count is only reliable for a single person without manual type filtering or a single org unit */
  const showTotalCount =
    (orgUnitIds.length === 1 && personIds.length === 0) ||
    (orgUnitIds.length === 0 && personIds.length === 1 && !filterByType);

  const language = props?.intl?.locale || 'de';
  const eCampusLangSuffix = language === 'en' ? '?sessionlanguage=en' : '';

  return isLoading ? (
    <Segment>
      <Loader size="large" inline="centered" active>
        <FormattedMessage
          id="Loading publications ..."
          defaultMessage="Loading publications ..."
        />
      </Loader>
    </Segment>
  ) : pubs.length === 0 ? (
    <Message warning>
      <FormattedMessage
        id="No matching publications found."
        defaultMessage="No matching publications found."
      />
    </Message>
  ) : (
    <div
      className={cx('ecampus-publications', { [citationStyle]: citationStyle })}
      key={`ecampus-pubs-${citationStyle}`}
    >
      {orgUnitIds.length === 0 &&
        pubsIds.length === 0 &&
        personIds.length === 1 &&
        props.data.link_to_ecampus && (
          <ECampusLink hisResId={personIds[0]} suffix={eCampusLangSuffix} />
        )}
      {pubs.map((pubData, index) => {
        const yearHeading =
          props.data?.divide_by_year === true &&
          curYear !== pubData['Publication.releaseYear']
            ? pubData['Publication.releaseYear']
            : false;
        curYear = pubData['Publication.releaseYear'];
        return (
          <div key={`pub-${props.id}-${index}`} className="publication-wrapper">
            {yearHeading && <h2>{pubData['Publication.releaseYear']}</h2>}
            <Publication
              data={pubData}
              linksDisabled={props.isEditMode}
              citationStyle={citationStyle}
              language={language}
            />
          </div>
        );
      })}
      {totalCount - pubs.length > 0 && (
        <center>
          <p>
            <ConditionalLink
              condition={props.data.link_to_ecampus}
              to={
                personIds.length === 1 && orgUnitIds.length === 0
                  ? `${ECAMPUS_BASE_URL}/qisserver/a/cs.psv.frontend/person/view/${personIds[0]}${eCampusLangSuffix}`
                  : personIds.length === 0 &&
                    orgUnitIds.length === 1 &&
                    orgUnitDefaultText.length > 0
                  ? `${ECAMPUS_BASE_URL}/qisserver/a/fs.res.frontend/pub/searchresult?navigationPosition=research,hisinonepublicationsearch&pageTitle=fs.res.publication.search.data&tableTitle=fs.res.publication.search.found&configFile=fs%2Fres%2FsearchPublication.xml&groups0.label=fs.res.common.basicData&groups0.criteria0.field=Publication.creators.creatorOrgunits.orgunit.defaulttext&groups0.criteria0.value=${orgUnitDefaultText}&${eCampusLangSuffix.slice(
                      1,
                    )}`
                  : `${ECAMPUS_BASE_URL}/qisserver/a/fs.res.frontend/pub/search${eCampusLangSuffix}`
              }
            >
              <Icon fitted name="plus" />{' '}
              {showTotalCount && <>{totalCount - pubs.length} </>}
              <FormattedMessage
                id="other publications ..."
                defaultMessage="other publications ..."
              />
            </ConditionalLink>
          </p>
        </center>
      )}
    </div>
  );
};

export default View;
